import React, { useEffect, useState, useContext, useRef } from "react";
import ContactInfo from "./ContactInfo";
import { navBarContext, authContext } from "./context/context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import BuildButton from "../assets/build-dark.png";
import SeekingBanner from "../assets/seeking-banner.png";
import Unicorn from "../assets/prairiwhite.png";
import { updateLatestActions } from "../utils";
import {
  Modal,
  PrairiButton,
  DeckPrompt,
  UnicornNote,
} from "@peterfosso/prairi-components";
import { storage } from "../firebase/firebaseApp";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  InvestorButton,
  TextButton,
  Loading,
  CompleteYourProfile,
  Badge,
} from "@peterfosso/prairi-components";
import Star from "../assets/silverstar.png";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
} from "../firebase/firebaseApp";

const StartupDashboard1 = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const [interestedStyle, setInterestedStyle] = useState({
    borderColor: "#92d050",
    color: "white",
  });
  const [FeedbackStyle, setFeedbackStyle] = useState({
    borderColor: "#a5a5a5",
    color: "#a5a5a5",
  });
  const [feedbacks, setFeedbacks] = useState([]);
  const [showInterested, setShowInterested] = useState(true);
  const [showFeedback, setShowFeedback] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});
  const [currentUserId, setCurrentUserId] = useState("");
  const [thankyouNote, setThankyouNote] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [know, setKnow] = useState("");
  const [deckError, setDeckError] = useState("")
  const [pitched, setPitched] = useState("");
  const [interestedInvestorsData, setInterestedInvestorsData] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [formError, setFormError] = useState(null);
  const [updatePending, setUpdatePending] = useState(false);
  const [userRef, setUserRef] = useState({});
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const time = Timestamp.fromDate(new Date());
  const [seekingPopup, setSeekingPopup] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadingDeck, setUploadingDeck] = useState(false);
  const [pitchFile, setPitchFile] = useState(null);
  const [pitchProgress, setPitchProgress] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const deckPattern =
    /^https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/prairi-prod-fe3a4\.appspot\.com\/.*/;
  const testUrl = (url) => deckPattern.test(url);

  const getDataFromUser = async (uid) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      // console.log("userData", JSON.stringify(userData))
      setCurrentUserData(userData);
      setCurrentUserId(userId);
      let ref = doc(db, "users", userId);
      setUserRef(ref);
      setFeedbacks(userData.investorFeedback.reverse());
      setUpdatePending(!testUrl(userData.formData.company.deck));
      // console.log("currentUserData inside getDataFromUser", currentUserData)
    } catch (error) {
      //console.log(error);
    }
  };

  const storeOkPopup = async (docId) => {
    const userRef = doc(db, "users", docId);
    updateLatestActions(
      userRef,
      "Clicked on 'welcome back' popup in db",
      isMobile
    );
  };

  const handleFileChange = (e) => {
    setPitchFile(e.target.files[0]);
    setFileName(e.target.files[0] ? e.target.files[0].name : "");
    setDeckError("")
  };

  const handleUpload = () => {
    return new Promise((resolve, reject) => {
      if (!pitchFile) {
        setDeckError("No file selected")
        reject("No file selected");
        return;
      }
      setUploadingDeck(true);
      const storageRef = ref(storage, `pdfs/${pitchFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, pitchFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPitchProgress(progress);
        },
        (error) => {
          console.error("Upload failed:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setPdfUrl(downloadURL);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleDeckEmail = async (pdfUrl) => {
    const payload = {
      sender: {
        name: "PRAIRI",
        email: "invite@prairi.com",
      },
      to: [
        {
          name: "Carolina",
          email: "kro.ruz@gmail.com",
        },
        {
          name: "Peter",
          email: "peter@prairi.com",
        },
        {
          name: "Peter",
          email: "pfosso@gmail.com",
        },
      ],
      subject: `Please review this updated deck`,
      textContent: "Please review this updated deck",
      templateId: 54,
      params: {
        COMPANY: currentUserData.formData.company.company,
        NEWDECK: `http://www.prairi.com/newdeck/${currentUserId}`,
      },
    };
    const { data } = await axios({
      method: "POST",
      url: "https://api.sendinblue.com/v3/smtp/email",
      headers: {
        Accept: "application/json",
        "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(payload),
    });
  };

  const handleSubmitDeck = async () => {
    try {
      let userRef = doc(db, "users", currentUserId);
      const pdfUrl = await handleUpload();
      await updateDoc(userRef, {
        pendingPitchDeck: pdfUrl,
      });
      handleUpload();
      updateLatestActions(userRef, "Submitted new deck for approval", isMobile);
      handleDeckEmail(pdfUrl);
      setUploadingDeck(false);
      setThankyouNote(true);
    } catch (error) {
      console.log(error);
    }
  };

  const redirectToForms = () => {
    //search for current position of the user in the forms
    storeOkPopup(currentUserId);
    navigate("/startupregistration");
  };

  const changeToSeeking = async (docId) => {
    const userRef = doc(db, "users", docId);
    await updateDoc(userRef, { seeking: "seeking", pendingApproval: true });
    updateLatestActions(
      userRef,
      "Seeking funding now! (pending approval)",
      isMobile
    );
    setSeekingPopup(false);
    navigate("/startupregistration");
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        // Assuming currentUser is defined in your component
        await getDataFromUser(currentUser.uid); // Pass the UID from currentUser
        setDataFetched(true);
      }
    } catch (error) {
      //console.log('error fetching data');
    }
  };

  const fetchInterestedById = async () => {
    try {
      if (currentUserData && currentUserData.investorInterests) {
        const interestedIds = currentUserData.investorInterests;

        const matchingUsers = [];

        // Use Promise.all to concurrently fetch all documents
        await Promise.all(
          interestedIds.map(async (userId) => {
            const userDocRef = doc(collection(db, "users"), userId);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
              const userData = { ...userDocSnap.data(), id: userDocSnap.id };
              matchingUsers.push(userData);
            } else {
              console.log(`User document with ID ${userId} not found`);
            }
          })
        );

        // console.log("Users with matching IDs:", matchingUsers);
        setInterestedInvestorsData(matchingUsers.slice().reverse());
        // console.log("interestedInvestorsData", JSON.stringify(interestedInvestorsData));
      }
    } catch (error) {
      // console.error("Error fetching users by ID:", error);
    }
  };

  const fetchContactsById = async () => {
    try {
      let contactsIds = [];
      if (currentUserData && currentUserData.investorContacts) {
        for (let i = 0; i < currentUserData.investorContacts.length; i++) {
          // console.log(JSON.stringify(currentUserData.investorContacts[i]))
          contactsIds.push(currentUserData.investorContacts[i].investorId);
        }

        const matchingUsers = [];
        // Use Promise.all to concurrently fetch all documents
        await Promise.all(
          contactsIds.map(async (userId) => {
            const userDocRef = doc(collection(db, "users"), userId);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
              const userData = { ...userDocSnap.data(), id: userDocSnap.id };
              matchingUsers.push(userData);
            } else {
              console.log(`User document with ID ${userId} not found`);
            }
          })
        );

        // console.log("Users with matching IDs:", matchingUsers);
        setContactsData(matchingUsers.slice());
      }
    } catch (error) {
      console.error("Error fetching users by ID:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // console.log("data fetched?")
  }, [currentUser]);

  useEffect(() => {
    if (currentUserData.category === "startup") {
      fetchInterestedById();
      fetchContactsById();
      setDataFetched(true);
    }
    if (currentUserData.category === "investor") {
      navigate("/dashboard");
    }
  }, [currentUserData]);

  const handleInterestedClick = () => {
    setInterestedStyle({ borderColor: "#92d050", color: "white" });
    setFeedbackStyle({ borderColor: "#a5a5a5", color: "#a5a5a5" });
    setShowInterested(true);
    setShowFeedback(false);
  };

  const handleFeedbackClick = () => {
    setInterestedStyle({ borderColor: "#a5a5a5", color: "#a5a5a5" });
    setFeedbackStyle({ borderColor: "#00b0f0", color: "white" });
    setShowFeedback(true);
    setShowInterested(false);
  };

  const handleKnowThem = (e) => {
    setKnow(e.target.value);
  };

  const handlePitchedThem = (e) => {
    setPitched(e.target.value);
  };

  const removeInvestorInterest = async (currentUserId, investorId) => {
    try {
      // Fetch the current user's document
      const userRef = doc(db, "users", currentUserId);
      const userDoc = await getDoc(userRef);
      //console.log("removeInvestorInterest userDoc" + JSON.stringify(userDoc.data()))
      if (userDoc.exists()) {
        //
        // Get the current "pendingMatches" array
        const investorInterests = userDoc.data().investorInterests;

        // Find and remove the match from the array
        const updatedMatches = investorInterests.filter(
          (match) => match !== investorId
        );
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(userRef, { investorInterests: updatedMatches });
        setInterestedInvestorsData(updatedMatches);

        //console.log(`Match ${matchId} removed from pendingMatches`);
      } else {
        //  console.log("User document not found");
      }
    } catch (error) {
      // console.error("Error removing match:", error);
    }
  };

  const addInvestorContact = async (currentUserId, investorId) => {
    try {
      const userRef = doc(db, "users", currentUserId);
      const userDoc = await getDoc(userRef);
      if (userDoc) {
        let updatedMatches;
        const userInvestorContacts = userDoc.data().investorContacts || [];
        const investorToAdd = {
          investorId,
          knowThem: know,
          pitchedThem: pitched,
        };
        if (!userInvestorContacts.includes(investorId)) {
          updatedMatches = [...userInvestorContacts, investorToAdd];
        }
        await updateDoc(userRef, { investorContacts: updatedMatches });
        updateLatestActions(
          userRef,
          "Answered if they know an investor",
          isMobile
        );
        // console.log(`Match ${matchId} added to interested`);
      }
    } catch (error) {
      //console.error("Error removing match:", error);
    }
  }; //

  const handleSubmit = async (currentUserId, investorId) => {
    if (know === "" || pitched === "") {
      setFormError(<div>Please, answer both questions.</div>);
      return;
    }
    const userRef = doc(db, "users", currentUserId);
    const userDoc = await getDoc(userRef);
    if (userDoc) {
      removeInvestorInterest(currentUserId, investorId);
      addInvestorContact(currentUserId, investorId);
      navigate(`/investorcontact/${investorId}`);
    }
  };

  useEffect(() => {
    // Adjust the container's height based on the content height
  }, [showInterested, showFeedback]);

  let navigate = useNavigate();

  const isTestStartup =
    currentUser && currentUser.uid === "BuqNkMYS9eWTrLouSLwDuI3X3Kq2";

  if (isTestStartup) {
    navigate("/startupregistration");
    return null;
  }

  if (!dataFetched) {
    return (
      <div
        className="md:max-w-lg p-2 from-navStart to-navEnd px-4 flex flex-col "
        ref={containerRef}
        style={{ marginTop: navBarHeight + 30 }}
      >
        <Loading />
      </div>
    ); // redirect to login, TO DO.
  }

  if (currentUserData.seeking === "NOT SEEKING") {
    return (
      <div
        className="md:max-w-lg p-1 pt-5 pb-2 from-navStart to-navEnd px-4 flex flex-col text-silverPrairi"
        ref={containerRef}
        style={{ marginTop: navBarHeight + 4 }}
      >
        <div className="font-bold my-5">
          Welcome, {currentUserData.contactName.split(" ")[0]}!
        </div>
        <div>
          This is your Dashboard. Since you’re{" "}
          <span className="font-bold">not</span> currently seeking funding,
          there’s not much to see here now but we’ll let you know when that
          changes.
        </div>
        <div className="flex flex-col ">
          <img
            src={BuildButton}
            className="w-full mt-[170px] lg:w-[90%] cursor-pointer"
            onClick={() => navigate("/buildastartup")}
          />
          <img
            src={SeekingBanner}
            className="w-[90%] mt-[80px] cursor-pointer"
            onClick={() => setSeekingPopup(true)}
          />
        </div>

        <Modal open={seekingPopup} border="green">
          <div className="flex flex-col justify-center items-center">
            <img src={Unicorn} className="w-1/5 mb-5" />
            <div className="mb-5">
              OK, now you'll create a profile for investors to see.{" "}
            </div>
            <div className="flex items-center">
              <TextButton
                label="undo"
                click={() => setSeekingPopup(false)}
                color="silver"
              />
              <PrairiButton
                click={() => changeToSeeking(currentUserId)}
                label="OK"
                rounded="soft"
                border="silver"
                size="sm"
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (
      <div
        className="md:max-w-lg p-1 pt-5 pb-2 from-navStart to-navEnd px-4 flex flex-col "
        ref={containerRef}
        style={{ marginTop: navBarHeight + 4 }}
      >
        {dataFetched && (
          <CompleteYourProfile
            open={
              !currentUserData.formData ||
              !currentUserData.formData.matching.completed
            }
            click={redirectToForms}
          />
        )}

        {dataFetched && !currentUserData.pendingPitchDeck &&(
          <div>
            <DeckPrompt
              open={updatePending}
              click={handleSubmitDeck}
              handleFileChange={handleFileChange}
              fileName={fileName}
              later={() => setUpdatePending(false)}
              loading={uploadingDeck}
              error={deckError}
            />
            <UnicornNote
              text="Thank you! Our team will review your new deck to ensure quality. We will notify you as
                      soon as it is approved. In the meantime, your
                      previous deck will remain visible to investors."
              open={thankyouNote}
              click={()=>navigate("/")}
            />
          </div>
        )}

        {showInterested && (
          <div className="flex space-x-8 justify-center items-start p-2 mb-5">
            <Badge
              content={interestedInvestorsData.length + contactsData.length}
              color="#303030"
              border="#92d050"
            >
              <button
                className="
                    font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                    w-[30vw] max-w-[150px] min-w-[94px]
                    sm:w-[150px] "
                style={interestedStyle}
                onClick={handleInterestedClick}
              >
                Interested
              </button>
            </Badge>
            <Badge content={feedbacks.length} color="#303030" border="#c8c8c8">
              <button
                className="            
                font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                w-[30vw] max-w-[150px] min-w-[94px]
                sm:w-[150px] "
                style={FeedbackStyle}
                onClick={handleFeedbackClick}
              >
                Feedback
              </button>
            </Badge>
          </div>
        )}

        {showFeedback && (
          <div className="flex space-x-8 justify-center items-start p-2 mb-5">
            <Badge
              content={interestedInvestorsData.length + contactsData.length}
              color="#303030"
              border="#c8c8c8"
            >
              <button
                className="
                    font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                    w-[30vw] max-w-[150px] min-w-[94px]
                    sm:w-[150px] "
                style={interestedStyle}
                onClick={handleInterestedClick}
              >
                Interested
              </button>
            </Badge>
            <Badge content={feedbacks.length} color="#303030" border="#00b0f0">
              <button
                className="            
                font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                w-[30vw] max-w-[150px] min-w-[94px]
                sm:w-[150px] "
                style={FeedbackStyle}
                onClick={handleFeedbackClick}
              >
                Feedback
              </button>
            </Badge>
          </div>
        )}

        {dataFetched &&
          showInterested &&
          (interestedInvestorsData.length > 0 || contactsData.length > 0)}
        {dataFetched &&
          showInterested &&
          interestedInvestorsData.length > 0 && (
            <div>
              {interestedInvestorsData.map((investor) => (
                <div
                  className="mb-2"
                  style={{
                    width: isMobile? "345px": "450px", // Default width for the button
                    fontSize: "small",
                    // Add more media queries and styles as needed
                  }}
                >
                  <InvestorButton
                    investor={investor}
                    submitted={false}
                    submit={() => handleSubmit(currentUserId, investor.id)}
                    handleKnowThem={handleKnowThem}
                    handlePitchedThem={handlePitchedThem}
                    error={formError}
                  />
                </div>
              ))}
            </div>
          )}

        {dataFetched && showInterested && contactsData.length > 0 && (
          <div>
            {contactsData.map((investor) => (
              <div
                className="mb-2"
                style={{
                  width: isMobile? "345px": "450px", // Default width for the button
                  fontSize: "small",
                  // Add more media queries and styles as needed
                }}
              >
                <InvestorButton
                  investor={investor}
                  submitted={true}
                  // submit={() => handleSubmit(currentUserId, investor.uid)}
                  handleKnowThem={handleKnowThem}
                  handlePitchedThem={handlePitchedThem}
                />
              </div>
            ))}
          </div>
        )}

        {showInterested &&
          dataFetched &&
          interestedInvestorsData.length === 0 &&
          contactsData.length === 0 && (
            <div>
              <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                No matches yet
              </h1>
              <p className="text-[#a5a5a5]">
                You’ll be notified via email and SMS whenever an investor shows
                that they are interested.
                <br /> <br />
                Their profiles will then appear here, after you have viewed
                them.
              </p>
            </div>
          )}
        {showFeedback && feedbacks.length > 0 && (
          <div>
            {feedbacks.map((feedback, index) => {
              let date = Object.keys(feedback)[0]; // Get the date from the object key
              let contents = feedback[date];
              return (
                <div>
                  <div className="text-silverPrairi mb-2">
                    <p>[{date.split("T")[0]}]</p>
                  </div>
                  <ul className="list-disc ml-4 mb-3">
                    <div>
                      {contents.map((fb, index) => (
                        <li key={index}>
                          <p>{fb}</p>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              );
            })}
          </div>
        )}
        {showFeedback && feedbacks.length === 0 && (
          <div>
            <h1 className="text-#f2f2f2 font-bold text-center pb-5">
              No feedback yet
            </h1>
          </div>
        )}
        {showFeedback && (
          <div className="text-silverPrairi flex flex-col items-center justify-start content-center mt-5 mb-5">
            <div className="flex justify-center items-start content-center text-center">
              <img src={Star} className="mr-5 mt-2" alt="star"></img>
              <div>
                <p className="text-left">
                  Be sure to{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => navigate("/startupregistration")}
                  >
                    update your profile
                  </span>{" "}
                  if there have been any changes or areas of growth in your
                  company.
                </p>
              </div>
            </div>
          </div>
        )}
        {!isMobile && (
          <div className="mt-80 bottom-10 justify-center text-center flex">
            <p className="text-[#505050] text-lg justify-center mt-10 text-center flex">
              PRAIRI is best on your mobile device
            </p>
          </div>
        )}
      </div>
    );
  }
};

export default StartupDashboard1;
