import React, { useEffect, useContext, useState } from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, 
  FacebookIcon, LinkedinIcon, WhatsappIcon, WhatsappShareButton, TwitterIcon } from "react-share";
  import axios from "axios";
import { dataContext, authContext } from "../context/context";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions, generateRandomString } from "../../utils";
import { FaCopy } from 'react-icons/fa';
import Heart from "../../assets/support_with-heart.png"
import ProfileComplete from "../../assets/profile_complete.png";
import Logo from "../../assets/prairiwhite.png";
import { useNavigate } from "react-router-dom";
import { Modal, TextButton, Rating, PrairiButton } from "@peterfosso/prairi-components";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../../firebase/firebaseApp";
const Matching = () => {
  const time = Timestamp.fromDate(new Date())
  const { currentUser } = useContext(authContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [currentUserData, setCurrentUserData] = useState({});
  const [currentUserId, setCurrentUserId] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState("")
  const [promo, setPromo] = useState("")
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();
  const handleRatingChange = async (value) => {
    setRating(value);
    try {
      let userRef = doc(db, "users", currentUserId);
    } catch (error) {
      console.log(error)
    }
};

const handleSendFeedback = async () => {
  try {
  let userRef = doc(db, "users", currentUserId)
  await updateDoc(userRef, {
    rating: {stars: rating, feedback: feedback}
  })
  setShowThankYou(true)
  setTimeout(() => {
    setShowThankYou(false)
  }, 3000)
  }  catch (error){
    console.log("Error saving rating")
  }
}


  const getDataFromUser = async (uid) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      // console.log("userData", JSON.stringify(userData))
      if (!userData.promo) {
        const newPromo = "ST" + + '0123456789'.charAt(Math.floor(Math.random() * 10)) + generateRandomString(4); 
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
          promo: newPromo
        });
        const promoCodeRef = collection(db, "promoCodes");
        await addDoc(promoCodeRef, {
        code: newPromo,
        discount: 100,
        users: 0
      });
        // Update userData with new promo
        userData.promo = newPromo;
      }

      setCurrentUserData(userData);
      setCurrentUserId(userId);
      // console.log("currentUserData inside getDataFromUser", currentUserData)
    } catch (error) {
      //console.log(error);
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(`Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at https://prairi.com`)
      .then(() => {
        alert('Text copied!');
        storeShare(currentUserId, "copy/paste")
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        // Assuming currentUser is defined in your component
        await getDataFromUser(currentUser.uid); // Pass the UID from currentUser
        // console.log("interestedStartupData inside fetchData", interestedStartupsData)
        // console.log("archivedStartupData inside fetchData", archivedStartupsData)
        setDataFetched(true);
      }
    } catch (error) {
      //console.log('error fetching data');
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentUser, currentUserData]);

  const storeShare = async (userId, media) => {
    const userRef = doc(db, "users", userId)
    updateLatestActions(userRef, `Shared ${media}`, isMobile)
  }

  const handlePendingApproval = async () => {
    try{
      let userRef = doc(db, "users", currentUserId)
  await updateDoc(userRef, {
    sentForReview: true
  })
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            name: "Carolina",
            email: "kro.ruz@gmail.com",
          },
          {
            name: "Peter",
            email: "peter@prairi.com",
          },
          {
            name: "Peter",
            email: "pfosso@gmail.com",
          },
        ],
        subject: `This user is ready to seek funding!`,
        textContent: "Please approve the following request",
        templateId: 50,
        params: {
          DECK: currentUserData.formData.company.deck,
          DOCID: currentUserId,
          // COUNTRY: country,
        },
      };
      const { data } = await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });
      navigate(`/`)
    }
    catch (e){
      console.log(e)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const {setDataFunc, globalData} = useContext(dataContext)
  const sharedMessage = `Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at https://prairi.com`
  const url = "https://www.prairi.com"
  const facebookShareUrl = `https://www.prairi.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${sharedMessage}`;
  const [messagePopup, setMessagePopup] = useState(false)
  const data = globalData.formData
  const allowed = data.company.completed && data.industry.completed 
                  && data.ownership.completed && data.support.completed && !globalData.pendingApproval
if (allowed){
  return (
    <div className="shadow-grey w-full  h-full rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <div className=" text-center  mt-5 ">
        <img
          className="h-12 mb-1 mx-auto items-center flex"
          src={Logo}
          alt="Matching"
        />
        <h1 className="font-bold text-center text-base ">Matching Starts</h1>
      </div>

      <div className="flex  mt-10">
        <img src={ProfileComplete} className="w-8 h-8 ml-2" alt="tick" />
        <div className="ml-5 mr-3">
          <p className="font-bold mb-3">Your profile is complete!</p>
          <p className="mb-3 ">
          PRAIRI will now (and in the future) match you with investors who fit your criteria. 
          </p>
          <p className="mb-3 font-bold">
          You’ll be notified via email and SMS whenever an investor shows they are interested.
          </p>
        </div>
      </div>

      {!currentUserData.rating && dataFetched && !showThankYou && <div className="flex text-left  flex-col items-start ml-[60px]">

        <p className="mt-8">How was the registration process?</p>
        <Rating onChange={handleRatingChange} initialValue={0} totalStars={5}/>
        <p>Anything we missed?</p>
        <input type="text" className="w-[160px] lg:w-[200px] h-[30px] mt-2 rounded-md text-[black] bg-customWhite" value={feedback}
          onChange={e => setFeedback(e.target.value)}></input>
          <div className="flex items-end justify-end w-1/2">
          <PrairiButton largerText bgColor="greenBg" label="Send" textColor="blackText" size="xs" rounded="soft" click={()=>handleSendFeedback()}/>
          </div>

      </div>}
      {/* {showThankYou && <div className="flex text-left text-silverPrairi flex-col items-start ml-[60px]">Thank you!</div>} */}

      <div className=" my-12 flex">
        <img src={Heart} className="h-12 "/>
        <div className="ml-4"><span className="font-bold">Know a startup</span> who might like PRAIRI? Here's a chance to spread some good karma by giving them the <span className="font-bold">gift to join for free</span> with this promo code: <span className="font-bold">{currentUserData.promo}</span></div>
        
      </div>

      <div className="mb-[500px] mt-5 text-silverPrairi  lg:flex items-center flex-col">
          <p className="mb-2  ml-[60px]">
            In the meantime... as we're a startup too, you can help us grow by <span className="font-bold">spreading the word!</span>
          </p>
          <p className="mb-5  ml-[60px]"> Just click any of the below links and copy/paste (or change) this pre-written <span className="underline font-bold cursor-pointer" onClick={()=>setMessagePopup(true)}>message.</span> It's fast and easy! Thanks in advance! :)</p>
          <div className="flex justify-between w-3/5 lg:w-2/5  mx-auto ">
            <TwitterShareButton url={url} title={sharedMessage} > 
              <TwitterIcon round size={32} onClick={()=>storeShare(currentUserId, "Twitter")}/>
            </TwitterShareButton>
            <FacebookShareButton url={facebookShareUrl}   >
              <FacebookIcon round size={32} onClick={()=>storeShare(currentUserId, "Facebook")}/>
            </FacebookShareButton>
            <LinkedinShareButton source={sharedMessage} url={url} title={sharedMessage} summary={sharedMessage}>
              <LinkedinIcon size={32} round onClick={()=>storeShare(currentUserId, "Linkedin")}/>
            </LinkedinShareButton>
            <WhatsappShareButton url={url} title={sharedMessage}>
              <WhatsappIcon size={32} round onClick={()=>storeShare(currentUserId, "Whatsapp")}/>
            </WhatsappShareButton>
          </div>
          <Modal open={messagePopup} border="silver" size="lg">
            <div className="pb-2 px-2">
              <div className="flex justify-end ">
              <button onClick={copyText} className="pr-5">
        <FaCopy />
      </button>
                <TextButton label="x" color="silver" click={()=>setMessagePopup(false)}/></div>
              <div><p>Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at https://prairi.com</p> 
              <p >https://www.prairi.com</p></div>
              
              
            </div>
          </Modal>
        </div>
    </div>
  );
}
else{
  return (
    <div className="shadow-grey w-full rounded-md mt-2 h-full md:max-w-xl mx-auto py-2.5 pb-[300px]">
    <div className="text-center mt-5">
      <img className="h-16 mb-1 mx-auto" src={Logo} alt="Matching" />
      <h1 className="font-bold text-center mb-8 text-base">Matching Pending</h1>
    </div>

<div className='flex'>

{!currentUserData.pendingApproval && dataFetched && <div className='ml-5 mr-3'>
<p className='font-bold mb-3'>Oops! Your profile is incomplete.</p>
<p className='mb-3 '>Please complete it so we can match your startup with investors from all around the world.</p>

</div>}
<Modal open={currentUserData.pendingApproval && !currentUserData.sentForReview && currentUserData.formData && currentUserData.formData.matching.completed} size="full" border="silver">
  <div className="p-2 flex flex-col items-center">
  <img className="h-16 mb-1 mx-auto" src={Logo} alt="Matching" />
  <div className="my-2">Thank you for completing your Data Profile! We'll review the information and let you know when the Matching starts. </div>
  <PrairiButton label="OK" border="green" bgColor="regular" rounded="soft" size="sm" click={()=>handlePendingApproval()}/>
  </div>

</Modal>

</div>
  </div>
  )
}

};

export default Matching;
