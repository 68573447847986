import React, { useEffect, useState, useContext } from "react";
import { navBarContext, authContext } from "./context/context";
import { useNavigate } from "react-router-dom";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  addDoc,
  getDoc,
  updateDoc,
  Timestamp,
  writeBatch,
  doc,
} from "../firebase/firebaseApp";
import {
  AdminDashboard,
  FetchButton,
  MatchCard,
  NoMatches,
  Loading,
  PrairiButton,
  TextButton,
  Modal,
} from "@peterfosso/prairi-components";
import { InvestorMatcher, filters } from "../Matching/InvestorMatcher";
import axios from "axios";
import { CgClose } from "react-icons/cg";
import { match } from "assert";
import { addToSummary } from "../utils";

const Near = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);

  const [startupsFetched, setStartupsFetched] = useState(false);
  const [investorsFetched, setInvestorsFetched] = useState(false);
  const [matchesFetched, setMatchesFetched] = useState(false);
  const [customFilters, setCustomFilters] = useState([]);
  const [filterError, setFilterError] = useState("");
  let tracker = 0;
  const { uid } = currentUser || {};

  const isAdminUser =
    currentUser && currentUser.uid === "A3Go76mJaLT2GeqHsxlYZRCOWur2";

  let [startups, setStartups] = useState([]);
  let [investors, setInvestors] = useState([]);
  const [matches, setMatches] = useState([]); //These are the ones that we'll render. customMatches-fullMatches.
  const [customMatches, setCustomMatches] = useState([])
  const [fullMatches, setFullMatches] = useState([]) //we'll use these to filter after applied custom filters.
  const [customMatchesData, setCustomMatchesData] = useState([]);
  const [fetchedMatches, setFetchedMatches] = useState([]);
  const [fetchButtonClicked, setFetchButtonClicked] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [approvePopup, setApprovePopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);

  let navigate = useNavigate();

  const investorMatcher = new InvestorMatcher();
  investorMatcher.addFilter("stage");
  investorMatcher.addFilter("amount");
  investorMatcher.addFilter("industry");
  investorMatcher.addFilter("location");

  const customMatcher = new InvestorMatcher();

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      // Si se selecciona y el total de seleccionados es menos de 3, añadir al array
      if (customFilters.length < 3) {
        setCustomFilters([...customFilters, name]);
      }
      if (customFilters.length === 3) {
        setFilterError("You can't select more than 3 filters.");
      }
    } else {
      // Si se deselecciona, quitar del array
      setCustomFilters(customFilters.filter((item) => item !== name));
      if (customFilters.length<4){
        setFilterError("")
      }
    }
  };

  const fetchInvestors = async () => {
    //console.log("Fetching investors...");
    const investorsCollection = query(
      collection(db, "users"),
      where("category", "==", "investor"),
      where("formData", "!=", null),
      where("formData.matching.completed", "==", true)
    );
    const snapshot = await getDocs(investorsCollection, { fromCache: false });
    const investorData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setInvestors(investorData);
    setInvestorsFetched(true);
    console.log("investors fetched");
  };

  const fetchStartups = async () => {
    //console.log("Fetching startups...");
    const startupsCollection = query(
      collection(db, "users"),
      where("category", "==", "startup"),
      where("formData", "!=", null),
      where("formData.matching.completed", "==", true)
    );
    const snapshot = await getDocs(startupsCollection, { fromCache: false });
    const startupData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setStartups(startupData);
    setStartupsFetched(true);
    console.log("startups fetched");
  };



  const applyFilters = async () => {
    if (!dataFetched) {
      return;
    }
    for (let filter of customFilters){
      customMatcher.addFilter(filter)
    }
    console.log(customFilters)
    const newMatches = [];
    setMatches([]); 
    for (const investor of investors) {
      try {
        const filteredStartups = startups.filter(
          (startup) =>
            !fetchedMatches.some(
              (match) =>
                match.investor === investor.id && match.startup === startup.id
            )
        );
        console.log(
          `filtered startups for investor ${investor.id}: ${filteredStartups.map((s) => s.id)}`
        );
  
        const fullFilteredDataIterator = investorMatcher.doFilter(investor, filteredStartups);
  
        for (const startup of fullFilteredDataIterator) { // Iterar sobre el generador
          fullMatches.push({
            investor: investor.id,
            startup: startup.id,
          });
        }

        const remainingStartups = filteredStartups.filter(startup => {
          return !fullMatches.some(match => match.startup === startup.id);
        });
        const customFilteredDataIterator = customMatcher.doFilter(investor, remainingStartups);

        for (const startup of customFilteredDataIterator) { // Iterar sobre el generador
          customMatches.push({
            investor: investor.id,
            startup: startup.id,
          });
        }
        
      } catch (error) {
        // Manejar errores (por ejemplo, cuando no se encuentran coincidencias)
        console.error("No matches found for investor:", investor);
      }
    }

    setMatches(customMatches);
    setFiltersApplied(true);
  };



  const fetchMatches = async () => {
    //So we don't repeat matches
    const matchesCollection = collection(db, "matches");
    console.log("Fetching matches...");
    const snapshot = await getDocs(matchesCollection, { fromCache: false });
    const fetchedMatches = snapshot.docs.map((doc) => {
      const matchData = doc.data();
      return {
        id: doc.id,
        ...matchData,
      };
    });
    setFetchedMatches(fetchedMatches);
    setMatchesFetched(true);
    console.log("Fetched existing matches");
  };

  const [dataFetched, setDataFetched] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const fetchData = async () => {
    await Promise.all([fetchStartups(), fetchInvestors(), fetchMatches()]); //add , fetchMatches()
    console.log("promise fetchData");
    // console.log(`investors ${JSON.stringify(investors)}`)
    console.log(`fetched matches: ${JSON.stringify(fetchedMatches)}`);
    setDataFetched(true);
  };

  const handleApplyFiltersClick = async () => {
    await fetchData(); // Wait for data to be fetched
    applyFilters(); // Now you can safely apply filters
    setFetchButtonClicked(true);
    console.log("handleApplyFilters");
  };

  const storePendingMatches = async (matches) => {
    const matchesCollection = collection(db, "pendingMatches");

    // Delete the entire collection
    const batch = writeBatch(db);
    const snapshot = await getDocs(matchesCollection);
    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    }
    // Store the new matches
    for (const match of matches) {
      const dataToStore = {
        investor: match.investor,
        startup: match.startup,
        date: Timestamp.fromDate(new Date()),
      };
      await addDoc(matchesCollection, dataToStore);
    }
  };

  const getMatchesData = async (matches) => {
    const usersCollection = collection(db, "users");
    const data = [];

    for (const match of matches) {
      console.log(`match inside for ${JSON.stringify(match)}`);
      console.log(`match.investor inside for ${match.investor}`);
      const investorDocRef = doc(usersCollection, match.investor);
      const investorSnapshot = await getDoc(investorDocRef);

      const investorData = {
        ...investorSnapshot.data(),
        id: investorSnapshot.id,
      };

      const startupDocRef = doc(usersCollection, match.startup);
      const startupSnapshot = await getDoc(startupDocRef);

      const startupData = {
        ...startupSnapshot.data(),
        id: startupSnapshot.id,
      };

      const matchData = {
        investor: investorData,
        startup: startupData,
      };
      console.log("match data empty");
      console.log(`match data: ${matchData}`);
      data.push(matchData);
    }

    // Set the matches data after the loop has completed
    console.log(
      `data previous setMatchesData in getMatchesData" ${JSON.stringify(data)}`
    );
    setCustomMatchesData(data);
  };

  //***********important************/
  const removeMatchFromData = (investorId, startupId) => {
    const updatedMatchesData = customMatchesData.filter(
      (match) =>
        match.investor.id !== investorId || match.startup.id !== startupId
    );
    setCustomMatchesData(updatedMatchesData);
  };

  //notifications management
  const handleEmailNotification = async (investor) => {
    // console.log(JSON.stringify(investor))
    try {
      // check if email is already used
      if (!investor) {
        throw new Error("User data is required.");
      }
      if (!investor.data().emailConsent) {
        console.log("no email consent");
        return; // Do not send email if emailConsent is false
      }
      //Send an email to investor about new match
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "info@prairi.com",
        },
        to: [
          {
            name: investor.data().contactName,
            email: investor.data().email,
          },
        ],
        subject:
          "Congratulations! PRAIRI has found new Startup matches for you.",
        templateId: 25,
        params: {
          DOCID: investor.id,
          EMAIL: investor.data().email,
        },
      };

      const { data } = await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });
      //console.log(data);
      const userRef = doc(db, "users", investor.id);

      const lastNotification = new Date().toISOString();
      await updateDoc(userRef, { lastNotification: lastNotification });
      setEmailSent(true);
    } catch (err) {
      //console.log("new match email sent to investor")
    }
  };

  const handleSMSNotification = async (investor) => {
    const messagesCollection = collection(db, "messages");
    //console.log(JSON.stringify(investor.data()))
    if (!investor.data().smsConsent) {
      console.log("no sms consent");
      return; // Do not send SMS if smsConsent is false
    }
    const dataToStore = {
      to: `+${investor.data().phone}`,
      body: `PRAIRI has new Startup matches for you. View them before they expire https://prairi.com/notifications/${investor.id} `,
    };
    await addDoc(messagesCollection, dataToStore);
  };

  const handleReject = async (investorId, startupId) => {
    try {
      const matchesCollection = collection(db, "matches");
      const dataToStore = {
        investor: investorId,
        startup: startupId,
        status: "pending",
        accepted: false,
        date: Timestamp.fromDate(new Date()),
      };
      await addDoc(matchesCollection, dataToStore);
      // console.log(JSON.stringify(matchRef))
      // Document successfully updated
      // console.log("Document updated successfully.");
      removeMatchFromData(investorId, startupId);
      setRejectPopup(false);
      tracker++;
    } catch (error) {
      // Handle the error
      //  console.log("Error updating document:", error);
    }
  };

  const handleApprove = async (investorId, startupId) => {
    try {
      const matchesCollection = collection(db, "matches");
      const dataToStore = {
        investor: investorId,
        startup: startupId,
        status: "pending",
        accepted: true,
        date: Timestamp.fromDate(new Date()),
      };
      await addDoc(matchesCollection, dataToStore);

      // const matchRef = doc(db, "pendingMatches", matchId);
      // await updateDoc(matchRef, { approved: true });

      // const matchDoc = await getDoc(matchRef);
      // //console.log("matchDoc", matchDoc.data())

      removeMatchFromData(investorId, startupId);

      const userRef = doc(db, "users", investorId);
      const userDoc = await getDoc(userRef);
      const startupRef = doc(db, "users", startupId);
      const startupDoc = await getDoc(startupRef);
      // console.log("userdoc", userDoc.data())
      if (startupDoc.exists()) {
        const userPendingMatches = userDoc.data().pendingMatches || [];
        const investorToAdd = investorId;
        if (!userPendingMatches.includes(investorToAdd)) {
          const updatedPendingMatches = [...userPendingMatches, investorToAdd];
          await updateDoc(startupRef, {
            pendingMatches: updatedPendingMatches,
          });
        }
      }
      if (userDoc.exists()) {
        console.log("enters if userDoc exists condition");

        const userPendingMatches = userDoc.data().pendingMatches || [];
        const startupToAdd = startupId;

        if (!userPendingMatches.includes(startupToAdd)) {
          // Add the startup to the array if it doesn't exist
          const updatedPendingMatches = [...userPendingMatches, startupToAdd];

          await updateDoc(userRef, {
            pendingMatches: updatedPendingMatches,
          });
          addToSummary(startupId, `Hasn't viewed profile yet`, investorId);
          const currentDate = new Date().toISOString();
          const lastNotificationDate =
            (userDoc.data().lastNotification || "").split("T")[0] ?? "";

          if (lastNotificationDate !== currentDate.split("T")[0]) {
            //console.log(`Last notification date: ${lastNotificationDate}`);
            //console.log(`Current date: ${currentDate.split('T')[0]}`);
            handleEmailNotification(userDoc);
            handleSMSNotification(userDoc);
          }
          setApprovePopup(false);
          //console.log("match added to investor");
        }
      }
      tracker++;
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (dataFetched && investorsFetched && startupsFetched) {
      applyFilters(); // Apply filters when data is fetched and investors/startups are updated
    }
  }, [dataFetched, investorsFetched, startupsFetched]);

  useEffect(() => {
    if (filtersApplied) {
      getMatchesData(matches); // Fetch and process matches data when filters are applied
    }
  }, [filtersApplied, matches, fetchedMatches]);

  useEffect(() => {
    if (filtersApplied) {
      fetchData(); // Fetch data when filters are applied
    }
  }, [filtersApplied]);

  useEffect(() => {}, [matches, customMatchesData, fetchedMatches]);

  if (isAdminUser) {
    return (
      <div
        className="px-4 flex flex-col justify-center w-full"
        style={{ marginTop: navBarHeight + 4 }}
      >
        <div className="">
          <div className="flex flex-col justify-center my-8 items-center">
            <form className="flex justify-between w-1/2">
              <label className="flex items-center w-full justify-center ">
                <input
                  type="checkbox"
                  name="location"
                  checked={customFilters.includes("location")}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Location
              </label>
              <br />
              <label className="flex items-center w-full justify-center ">
                <input
                  type="checkbox"
                  name="industry"
                  checked={customFilters.includes("industry")}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Industry
              </label>
              <br />
              <label className="flex items-center w-full justify-center">
                <input
                  type="checkbox"
                  name="stage"
                  checked={customFilters.includes("stage")}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Stage
              </label>
              <br />
              <label className="flex items-center w-full justify-center">
                <input
                  type="checkbox"
                  name="amount"
                  checked={customFilters.includes("amount")}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                Amount
              </label>
            </form>
<p className="text-xs text-redPrairi my-2">{filterError}</p>
            <PrairiButton
              border="yellow"
              bgColor="regular"
              label="Fetch Near Matches"
              size="lg"
              rounded="soft"
              click={handleApplyFiltersClick}
            />
          </div>
          {!filtersApplied && dataFetched && <Loading />}
          {filtersApplied && matchesFetched && customMatchesData.length > 0 && (
            <div className="flex justify-center">
              {customMatchesData.length > 0 ? (
                <div>
                  {customMatchesData.map(({ investor, startup }) => {
                    const matchId = `${investor.id}-${startup.id}`;
                    return (
                      <div>
                        <MatchCard // Add a unique key
                          key={matchId}
                          match={{ investor, startup }}
                          reject={() => setRejectPopup(matchId)}
                          approve={() => setApprovePopup(matchId)}
                        />
                        <Modal border="green" open={approvePopup === matchId}>
                          <div className=" m-3">
                            <p>
                              {" "}
                              You're about to{" "}
                              <span className="bold">APPROVE</span> the match
                              between {investor.contactName} with{" "}
                              {startup.formData.company.company}.
                            </p>
                          </div>
                          <div className="flex justify-between items-center  w-[80%]">
                            <PrairiButton
                              bgColor="regular"
                              border="green"
                              size="lg"
                              rounded="full"
                              label="YES, NOTIFY INVESTOR"
                              click={() =>
                                handleApprove(investor.id, startup.id)
                              }
                            />
                            <TextButton
                              label="undo"
                              click={() => setApprovePopup(false)}
                            />
                          </div>
                        </Modal>
                        <Modal border="red" open={rejectPopup === matchId}>
                          <div className=" m-3">
                            <p>
                              {" "}
                              You're about to{" "}
                              <span className="bold">REJECT</span> the match
                              between {investor.contactName} with{" "}
                              {startup.formData.company.company}.
                            </p>
                          </div>
                          <div className="flex justify-between items-center  w-[80%]">
                            <PrairiButton
                              bgColor="regular"
                              border="red"
                              size="lg"
                              rounded="full"
                              label="YES, REJECT"
                              click={() =>
                                handleReject(investor.id, startup.id)
                              }
                            />
                            <TextButton
                              label="undo"
                              click={() => setRejectPopup(false)}
                            />
                          </div>
                        </Modal>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoMatches />
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <>Access forbidden</>;
  }
};

export default Near;
