import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics'; 
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  Timestamp,
  updateDoc,
  query,
  where,
  getDoc,
  writeBatch,
  getDocs,
  deleteField,
  deleteDoc,
  FieldValue
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier, 
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPhoneNumber 
} from "firebase/auth";
import { getStorage } from 'firebase/storage';

const {
  REACT_APP_FIREBASE_API_KEY_PROD,
  REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
  REACT_APP_FIREBASE_PROJECT_ID_PROD,
  REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
  REACT_APP_FIREBASE_SENDER_ID_PROD,
  REACT_APP_FIREBASE_APP_ID_PROD,
  REACT_APP_FIREBASE_MEASUREMENT_ID_PROD,
  REACT_APP_DATABASE_URL_PROD,
} = process.env;

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
  databaseURL: process.env.REACT_APP_DATABASE_URL_PROD,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID_PROD,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD
});

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);

export {
  auth,
  createUserWithEmailAndPassword,
  db,
  addDoc,
  doc,
  signOut,
  Timestamp,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  sendPasswordResetEmail,
  deleteDoc,
  updateDoc,
  collection,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
  query,
  where,
  getDoc,
  getDocs,
  writeBatch,
  analytics,
  FieldValue,
  deleteField,
  onAuthStateChanged,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  storage
};
