import React, { useState, useContext, useEffect } from "react";
/*import Switch from "react-switch";*/
import IndustryLogo from "../../assets/industry.png";

import { updateLatestActions, getDataFromUser } from "../../utils";
import { useMediaQuery } from "react-responsive";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "../../firebase/firebaseApp";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import Slider from "rc-slider";
import GreenTick from "../../assets/greentick.png";
import { withStyles } from "@material-ui/core/styles";
import "rc-slider/assets/index.css";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import {fetchExchangeRates} from "../../utils"
import { investorContext, authContext } from "../context/context";
import NextButton from "./../common/NextButton/NextButton";
import { set } from "firebase/database";

const InvestorIndustry = (props) => {
  const { setDataFunc, globalData } = useContext(investorContext);
  const industryData = globalData.formData.industry;
  const industryArray = industryData.industry;
  const { startupcountries } = globalData.formData.company;
  const [userRef, setUserRef] = useState([])
  const [rates, setRates] = useState(null)
  let industryNumber = 0;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  for (const key in industryArray) {
    if (industryArray[key] === true) {
      industryNumber++;
    }
  }
  const { currentUser } = useContext(authContext);
  const [checkedValues, setCheckedValues] = useState(industryNumber);
  const [data, setData] = useState({
    industry: industryData.industry || "",
    stage: industryData.stage || "",
    currency: industryData.currency || "",
    amount: industryData.amount || "",
    model: industryData.model || "",
    type: industryData.type || "",
    error: "",
    loading: false,
    message: "",
  });
 
  useEffect(() => {
    const fetchUserRef = async () => {
      try {
        const {userId} = await getDataFromUser(currentUser.uid)
        let ref = doc(db, "users", userId)
        setUserRef(ref)
      } catch (error){
        console.log(error)
      }
    }
    fetchUserRef();
  }, [currentUser.uid])
  let isAmplified;
  if (data.currency === "SEK") {
    if (data.amount[1] > 100000000) {
      isAmplified = true;
    } else {
      isAmplified = false;
    }
  }
  if (data.currency !== "SEK") {
    if (data.amount[1] > 10000000) {
      isAmplified = true;
    } else {
      isAmplified = false;
    }
  }

  const [amplified, setAmplified] = useState(isAmplified);
  const [moreThan70Selected, setMoreThan70Selected] = useState({
    moreThan70Selected: false,
    showWarning: false,
  });
  const [allSelected, setAllSelected] = useState(Object.keys(industryData.industry).filter((key) => industryData.industry[key]).length===20)
  const [showWhy, setShowWhy] = useState(false);
  const toggleAmplify = () => {
    setAmplified(!amplified);

  };


  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  let navigate = useNavigate();
  const industrySelectOptions = [
    { name: "AdTech/Media", value: "AdTech/Media" },
    { name: "Big Data", value: "Big Data" },
    { name: "Consumer Goods", value: "Consumer Goods" },
    { name: "Cybersecurity", value: "Cybersecurity" },
    {
      name: "Deep Tech (AI, Web3, Blockchain, etc.)",
      value: "Deep Tech (AI, Web3, Blockchain, etc.)",
    },
    {
      name: "Emerging Tech (NFTs, VR, Robotics)",
      value: "Emerging Tech (NFTs, VR, Robotics)",
    },
    { name: "eCommerce", value: "eCommerce" },
    { name: "EdTech", value: "EdTech" },
    { name: "Entertainment/Music/Gaming", value: "Entertainment/Music/Gaming" },
    { name: "Fashion", value: "Fashion" },
    { name: "FoodTech", value: "FoodTech" },
    { name: "Fintech/Finance", value: "Fintech/Finance" },
    { name: "Greentech/Cleantech/Energy", value: "Greentech/Cleantech/Energy" },
    { name: "HealthTech/MedTech", value: "HealthTech/MedTech" },
    { name: "IT/Software/SaaS", value: "IT/Software/SaaS" },
    { name: "PropTech", value: "PropTech" },
    { name: "Retail", value: "Retail" },
    { name: "Service Industry Solutions", value: "Service Industry Solutions" },
    { name: "Space Technology", value: "Space Technology" },
    {
      name: "Transport and Logistics (Shared Mobility/Maritime/Delivery Services)",
      value:
        "Transport and Logistics (Shared Mobility/Maritime/Delivery Services)",
    },
  ];
  const stageSelectOptions = [
    { name: "Early/Seed", value: "Early/Seed" },
    { name: "Growth", value: "Growth" },
    { name: "Series A", value: "Series A" },
    { name: "Series B/Higher", value: "Series B/Higher" },
  ];

  const modelSelectOptions = [
    { name: "B2C", value: "B2C" },
    { name: "B2B", value: "B2B" },
  ];

  const productTypeSelectOptions = [
    {name: "Software", value: "Software"},
    {name: "Hardware", value: "Hardware"},
    {name: "SaaS", value: "SaaS"},
    {name: "Other", value: "Other"}
  ]

  const currencySelectOptions = ["EUR", "USD", "GBP", "SEK"];

  useEffect(() => {
    const fetchRates = async () => {
      const rates = await fetchExchangeRates();
      if (rates) {
        setRates(rates);
      }
    };
  
    fetchRates();
  }, []);

  const selectIndustryOptionsHandler = () => {
    const updatedIndustryData = {};
    for (let i = 0; i < industrySelectOptions.length; i++) {
      const fieldData = industrySelectOptions[i];
      updatedIndustryData[fieldData.value] = true;
    }
    setData({
      ...data,
      industry: updatedIndustryData,
    });
  };

  const unselectIndustryOptionsHandler = () => {
    const updatedIndustryData = {};
    for (let i = 0; i < industrySelectOptions.length; i++) {
      const fieldData = industrySelectOptions[i];
      updatedIndustryData[fieldData.value] = false;
    }
    setData({
      ...data,
      industry: updatedIndustryData,
    });
  };

  const handleChange = (e, category) => {
    //console.log(`morethan70 ${JSON.stringify(moreThan70Selected)}`);
    if (category === "industry" && moreThan70Selected) {
      const updatedData = {
        ...data,
        [category]: {
          ...data[category],
          [e.target.name]: e.target.checked,
        },
      };

      const checkedCount = Object.values(updatedData[category]).filter(
        Boolean
      ).length;

      //console.log(`checkedCount: ${checkedCount}`);

      if (checkedCount <= 7) {
        setData({
          ...data,
          [category]: {
            ...data[category],
            [e.target.name]: e.target.checked,
          },
        });
        setCheckedValues(checkedCount);
      } else if (checkedValues > 7 && !moreThan70Selected) {
        setData({
          ...data,
          [category]: {
            ...data[category],
            [e.target.name]: e.target.checked,
          },
        });
      } else {
        // Unchecked case - decrease the checkedValues state
        setMoreThan70Selected({ ...moreThan70Selected, showWarning: true });
        setCheckedValues(checkedCount);
      }
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
        [category]: {
          ...data[category],
          [e.target.name]: e.target.checked,
        },
        selectedCurrency: e.target.value,
      });
    }
  };

  const convertCurrency = (amount, fromCurrency, toCurrency) => {

    if (fromCurrency === toCurrency) {
      return amount;
    }

    const rate = rates[fromCurrency][toCurrency];
    return [Math.ceil(amount[0] * rate), Math.ceil(amount[1] * rate)];
  };

  const handleSubmit = (e) => {
    // console.log(JSON.stringify(data))
    const time = Timestamp.fromDate(new Date())
    const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    e.preventDefault();
    let industries = [];
    let stages = [];
    let models = [];
    let types = [];
    industries = Object.keys(industry).filter((key) => industry[key]);
    stages = Object.keys(stage).filter((key) => stage[key]);
    models = Object.keys(model).filter((key) => model[key]);
    types = Object.keys(type).filter((key) => type[key])
    if (industries.length < 1 || stages.length < 1 || models.length < 1 || types.length <1) {
      setData({ ...data, error: "Fill all the required fields." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });
      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            industry: {
              ...globalData.formData.industry,
              industry,
              stage,
              currency,
              amount,
              model,
              type,
              completed: true,
            },
          },
        });
        navigate("/investorregistration/founders");
        updateLatestActions(userRef, "Saved industry form", isMobile)
      }, 2000);
    }
  };

  useEffect(() => {
    const getStartupCountries = () => {
      setMoreThan70Selected(
        startupcountries.includes("ANYWHERE") || startupcountries.length > 69
      );
    };

    getStartupCountries();
  }, [startupcountries]);

  const {
    industry,
    stage,
    currency,
    type,
    amount,
    model,
    error,
    message /* loading*/,
  } = data;

  useEffect(() => {
    // Update slider value when currency changes
    let newAmount = convertCurrency(
      industryData.amount,
      industryData.currency,
      data.currency
    );
    if (data.currency === "SEK" && !isAmplified) {
      if (newAmount[0] < 1000000) {
        newAmount[0] = 1000000;
      }
      if (newAmount[1] > 100000000) {
        newAmount[1] = 100000000;
      }
    } else {
      if (newAmount[0] < 100000 && !isAmplified) {
        newAmount[0] = 100000;
      }
      if (newAmount[1] > 10000000 && !isAmplified) {
        newAmount[1] = 10000000;
      }
    }
    setData({
      ...data,
      amount: newAmount,
    });
  }, [currency]);

  useEffect(() => {
    // Set initial slider extremes based on the currency when the component mounts
    setData({
      ...data,
      amount: industryData.amount,
    });
  }, []);

  useEffect(() => {
    // Check if all industries are selected
    const allSelected = Object.values(data.industry).every((value) => value);
    setAllSelected(allSelected);
  }, [data.industry]);

  return (
    <div>
        <div className="flex justify-center mt-5 text-yellowPrairi">The following 4 criteria are 'deal breakers' and must have also been selected by the startup to match with them:</div>
    <div className="shadow-grey w-[90%]  rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      
      <form className=" " onSubmit={handleSubmit}>
        <div className="my-2 ">
        
          <div className="flex items-end ">
            <div className="flex items-end pl-3 md:pl-0">
              <div
                className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
              >
                <img className=" h-full  " src={IndustryLogo} alt="Company" />
              </div>
              <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
                Industries
              </h1>
            </div>
            <div
              onMouseEnter={() => setShowWhy(true)}
              onMouseLeave={() => setShowWhy(false)}
              onTouchStart={() => setShowWhy(true)}
              onTouchEnd={() => setShowWhy(false)}
              className="flex  items-end"
            >
              {allSelected && <input
                type='button'
                value="UNSELECT ALL"
                onClick={unselectIndustryOptionsHandler}
                className=" uppercase  cursor-pointer ml-4 text-sm text-gray-500 "
                >
              </input>}
              {!allSelected && <input
                disabled={moreThan70Selected}
                type="button"
                value="Select All"
                onClick={selectIndustryOptionsHandler}
                className=" uppercase  cursor-pointer ml-4 text-sm text-gray-500 disabled:cursor-not-allowed"
              ></input>}
              {showWhy && moreThan70Selected && (
                <div className="bg-black z-10 border text-[10px] lg:text-[15px] border-white rounded-lg p-2 absolute ml-[50px] lg:w-[200px] w-[150px]">
                  Since you selected more than 70 countries in the 'Match
                  Processing' section you are limited to 7 industries. You can
                  go back by clicking on the progress bar.
                </div>
              )}
            </div>
            {}
          </div>
          {industrySelectOptions.map((option, index) => {
            return (
              <label
                key={option.name}
                className=" px-3 md:pl-24 flex items-center my-2 cursor-pointer"
              >
                <input
                  className="hidden"
                  type="checkbox"
                  name={option.value}
                  value={option.value}
                  onChange={(e) => handleChange(e, "industry")}
                ></input>
                <div className="w-6 h-6 relative border border-white overflow-visible">
                  {industry[option.value] ? (
                    <div className="absolute -top-2  z-5  w-8  text-green-700 text-xl">
                      <img src={GreenTick} alt="check" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="ml-2">{option.name}</div>
              </label>
            );
          })}

          <div className="my-5 ">
            <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white uppercase font-bold">
              Business model preference
            </h1>
            {modelSelectOptions.map((option, index) => {
              return (
                <label
                  key={option.name}
                  className=" px-3 md:pl-24 flex items-center my-2 cursor-pointer"
                >
                  <input
                    className="hidden"
                    type="checkbox"
                    name={option.value}
                    value={option.value}
                    onChange={(e) => handleChange(e, "model")}
                  ></input>
                  <div className="w-6 h-6 relative border border-white overflow-visible">
                    {model[option.value] ? (
                      <div className="absolute -top-2  z-5  w-8  text-green-700 text-xl">
                        <img src={GreenTick} alt="check" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-2">{option.name}</div>
                </label>
              );
            })}
          </div>

          <div className="my-5 ">
            <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white uppercase font-bold">
              Product focus preference
            </h1>
            {productTypeSelectOptions.map((option, index) => {
              return (
                <label
                  key={option.name}
                  className=" px-3 md:pl-24 flex items-center my-2 cursor-pointer"
                >
                  <input
                    className="hidden"
                    type="checkbox"
                    name={option.value}
                    value={option.value}
                    onChange={(e) => handleChange(e, "type")}
                  ></input>
                  <div className="w-6 h-6 relative border border-white overflow-visible">
                    {type[option.value] ? (
                      <div className="absolute -top-2  z-5  w-8  text-green-700 text-xl">
                        <img src={GreenTick} alt="check" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-2">{option.name}</div>
                </label>
              );
            })}
          </div>


        </div>
        <div className="my-5 ">
          <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white uppercase font-bold">
            Stages
          </h1>
          {stageSelectOptions.map((option, index) => {
            return (
              <label
                key={option.name}
                className=" px-3 md:pl-24 flex items-center my-2 cursor-pointer"
              >
                <input
                  className="hidden"
                  type="checkbox"
                  name={option.value}
                  value={option.value}
                  onChange={(e) => handleChange(e, "stage")}
                ></input>
                <div className="w-6 h-6 relative border border-white overflow-visible">
                  {stage[option.value] ? (
                    <div className="absolute -top-2  z-5  w-8  text-green-700 text-xl">
                      <img src={GreenTick} alt="check" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="ml-2">{option.name}</div>
              </label>
            );
          })}
        </div>


        <div className="mb-5 mt-12 ">
          <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white uppercase font-bold">
            Investment Range
          </h1>
          <div className="mt-5 px-3 md:pl-24">
            <div>
              <h1 className="mb-2">Currency</h1>
              <div className="inline-flex items-center bg-buttonParent  py-1 pl-2">
                {currencySelectOptions.map((currentCurrency, index) => {
                  return (
                    <input
                      className={`uppercase mr-1 ${
                        currentCurrency === currency
                          ? "bg-[#303030] opacity-100 border border-[#00b0f0]  "
                          : " bg-inactiveButton opacity-50"
                      } text-xs cursor-pointer rounded border-solid  py-1 px-4  text-white outline-none `}
                      key={currency + index}
                      type="button"
                      value={currentCurrency}
                      name="currency"
                      onClick={handleChange}
                    ></input>
                  );
                })}
              </div>
            </div>
            <div className="mt-2">
              <div className="flex items-start flex-col">
                <div className="w-9/12  ">
                  {currency !== "SEK" && (
                    <div className="flex items-center">
                    <Slider
                      range
                      step={amplified ? 1000000 : 100000}
                      min={amplified ? 1000000 : 100000}
                      max={amplified ? 100000000 : 10000000}
                      allowCross={false}
                      defaultValue={[...amount]}
                      value={[...amount]}
                      onChange={(values) =>
                        setData({ ...data, amount: [...values] })
                      }
                      trackStyle={[
                        { backgroundColor: "#00b0f0", height: "6px" },
                      ]}
                      railStyle={{ backgroundColor: "grey", height: "6px" }}
                      handleStyle={[
                        {
                          height: "15px",
                          width: "15px",
                          borderColor: "grey",
                          boxShadow: "none",
                          borderWidth: "0px",
                          borderRadius: "2px",
                          backgroundColor: "grey",
                          outline: "none",
                          opacity: 1,
                        },
                        {
                          height: "15px",
                          width: "15px",
                          borderColor: "grey",
                          borderWidth: "0px",
                          borderRadius: "2px",
                          boxShadow: "none",
                          backgroundColor: "grey",
                          outline: "none",
                          opacity: 1,
                        },
                      ]}
                    />
                      <button type="button" 
                       onClick={toggleAmplify}
                      className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${amplified? "border-bluePrairi border-solid":"border-[#303030]"}`}>+10x</button>
                    </div>
                  )}
                  {currency === "SEK" && (
                    <div className="flex items-center">
                    <Slider
                      range
                      step={amplified ? 1000000 : 100000}
                      min={amplified ? 10000000 : 1000000}
                      max={amplified ? 1000000000 : 100000000}
                      allowCross={false}
                      defaultValue={[...amount]}
                      value={[...amount]}
                      onChange={(values) =>
                        setData({ ...data, amount: [...values] })
                      }
                      trackStyle={[
                        { backgroundColor: "#00b0f0", height: "6px" },
                      ]}
                      railStyle={{ backgroundColor: "grey", height: "6px" }}
                      handleStyle={[
                        {
                          height: "15px",
                          width: "15px",
                          borderColor: "grey",
                          boxShadow: "none",
                          borderWidth: "0px",
                          borderRadius: "2px",
                          backgroundColor: "grey",
                          outline: "none",
                          opacity: 1,
                        },
                        {
                          height: "15px",
                          width: "15px",
                          borderColor: "grey",
                          borderWidth: "0px",
                          borderRadius: "2px",
                          boxShadow: "none",
                          backgroundColor: "grey",
                          outline: "none",
                          opacity: 1,
                        },
                      ]}
                    />
                     <button type="button" 
                       onClick={toggleAmplify}
                      className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${amplified? "border-bluePrairi border-solid":"border-[#303030]"}`}>+10x</button>
                    </div>
                  )}
                </div>
                <div className="ml-2 text-sm md:text-base font-bold flex items-center">
                  Range:{" "}
                  <CurrencyFormat
                    className="mx-1.5"
                    displayType="text"
                    thousandSeparator={true}
                    value={amount[0]}
                  />{" "}
                  -{" "}
                  <CurrencyFormat
                    className="ml-1.5"
                    displayType="text"
                    thousandSeparator={true}
                    value={amount[1]}
                  />
                  {((amount[1] === 100000000 && currency === "SEK") ||
                    (amount[1] === 10000000 && currency !== "SEK")) && (
                    <span className="ml-0">+</span>
                  )}
                  <span className="ml-1.5 ">{currency.toUpperCase()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">
            Saving...{" "}
            <span className="text-silverPrairi">
              (click progress bar if you wish to edit)
            </span>
          </p>
        )}
        <div className="flex justify-center my-5 px-3 md:pl-24">
          <NextButton value="SAVE" />
        </div>
      </form>
      {moreThan70Selected.showWarning && (
        <div
          className="
        absolute inset-0 top-0  left-0 w-full h-full 
"
        >
          <div
            onClick={() =>
              setMoreThan70Selected({
                ...moreThan70Selected,
                showWarning: false,
              })
            }
          ></div>
          <div
            style={{ zIndex: 99999 }}
            className="fixed top-1/4 left-0 right-0 w-full  md:w-1/2 lg:w-2/5 mt-24 mb-100 mx-auto py-2 pl-4 pr-2  rounded-xl  z-20 bg-backgroundPrairi text-customWhite shadow-grey shadow-md "
          >
            <p className="my-2">
              Since you selected more than 70 countries in the 'Match
              Processing' section you are limited to 7 industries. You can go
              back by clicking on the progress bar.
            </p>

            <button
              className="block bg-textPrairi text-black py-1 px-4 rounded my-2 mx-auto"
              onClick={() =>
                setMoreThan70Selected({
                  ...moreThan70Selected,
                  showWarning: false,
                })
              }
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default InvestorIndustry;
