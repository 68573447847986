import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { PrairiButton } from "@peterfosso/prairi-components";
import { navBarContext, authContext } from "./context/context";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  doc,
  Timestamp,
  updateDoc,
  addDoc,
} from "../firebase/firebaseApp";
import { updateLatestActions } from "../utils";

const Approve = () => {
  const { navBarHeight } = useContext(navBarContext);
  let { docId } = useParams();
  const [userData, setUserData] = useState(null);
  const [name, setName] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [approved, setApproved] = useState(false)
  const { currentUser } = useContext(authContext);
  const { uid } = currentUser || {};
  let navigate = useNavigate();
  const isAdminUser = currentUser && currentUser.uid === "A3Go76mJaLT2GeqHsxlYZRCOWur2";

  const getDataFromDb = async () => {
    try {
      const preUserRef = doc(db, "preUsers", docId);
      const preUserSnap = await getDoc(preUserRef);
      const preUserEmail = preUserSnap.data().email;
      const preUserPrivateQ = query(collection(db, "preUsersPrivate"), where("email", "==", preUserEmail));
      const querySnapshot = await getDocs(preUserPrivateQ);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setUserData(userData);
      setDataFetched(true);
      return { userData, userId };
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvite = async () => {
    try {
      const preUserRef = doc(db, "preUsers", docId);
      const preUserSnap = await getDoc(preUserRef);

      if (preUserSnap.exists()) {
        const preUserData = preUserSnap.data();
        const email = preUserData.email;

        const preUsersPrivateQuery = query(
          collection(db, "preUsersPrivate"),
          where("email", "==", email)
        );
        const preUsersPrivateSnap = await getDocs(preUsersPrivateQuery);

        if (!preUsersPrivateSnap.empty) {
          const preUsersPrivateData = preUsersPrivateSnap.docs[0].data();

          const combinedData = {
            ...preUsersPrivateData,
            ...preUserData,
            invited: true,
            rejected: false,
            invitedAt: Timestamp.fromDate(new Date()),
          };

          await addDoc(collection(db, "users"), combinedData);
          await updateDoc(preUserRef, {
            invited: true
          })
          updateLatestActions(preUserRef, "Invitation sent", false)
          console.log("Document successfully updated.");
        } else {
          console.log("No matching document found in preUsersPrivate.");
        }
      } else {
        console.log("No matching document found in preUsers.");
      }
    } catch (error) {
      console.log("Error updating document:", error);
    }
  };

  const sendInvitationCode = async () => {
    try {
      const docRef = doc(db, "preUsers", docId);
      const docSnap = await getDoc(docRef);
      const { username, code, email } = docSnap.data();
      setName(username);
      let nameArray = username.split(" ");
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            username,
            email,
          },
        ],
        templateId: 27,
        params: {
          NAME: nameArray[0],
          CODE: code,
          DOCID: docId,
        },
      };
      const response = await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });

      const emailId = response.data.messageId;

      await updateDoc(docRef, {
        invitationEmail: {
          id: emailId,
          opened: false, // Inicialmente el email no ha sido abierto
        }
      });
      console.log(`Email enviado y guardado con ID: ${emailId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprove = async () => {
    if (currentUser && isAdminUser) {
      await sendInvitationCode();
      await handleInvite();
      setApproved(true)
    } else {
      navigate("/404");
    }
  };

  useEffect(() => {
      getDataFromDb();
  }, [docId, isAdminUser, currentUser, navigate]);

if (isAdminUser){
  return (
    <div className={`w-full`} style={{ marginTop: navBarHeight + 4 }}>
      {dataFetched && (
        <div className="flex flex-col justify-center items-center mt-4">
          <PrairiButton click={handleApprove} size="lg" border="green" label={`Approve ${userData.username}`} />
        {approved && <h1 className="my-4 text-center text-lg md:text-xl">
          Invitation code sent to {name}!!
        </h1>}
        </div>
      )}
    </div>
  );
}
else{
  return(<>forbidden access</>)
  
}

};

export default Approve;
